import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import BodyContact from '../components/BodyContact/BodyContact'

const Contact = () => {
    return (
        <div>
            <Navbar />
            <BodyContact />
            <Footer />
        </div>
    )
}

export default Contact