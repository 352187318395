import './Slide.css'
import { ListFilms } from '../../data'
import { Link } from 'react-router-dom';

const Slide = () => {

  const film = ListFilms.filter(film=>film.id===6)[0];

  return (
    <Link to={'/film/' + film.id} style={{ textDecoration: 'none' }}>
      <div className='slideContainer'>
        <img src="/img/My Dad's A farmer 1.png" alt='' className='imgSlider' />
        <div className='infoSlider'>
          <span className='text1'>{film.title}</span>
        </div>
      </div></Link>
  )
}

export default Slide
