export const ListFilms = [
    {
        id: 1,
        img: '/img/films/nice very nice.jpg',
        title: 'Nice very nice',
        synopcis: 'Didou is 88 years old and has spent his life caring for the memory of his deceased wife. Her house is decorated with mosaïque and seashells and the walls are lined with pictures of her. Having become a guide in the Casbah, he evokes his wife with passion to tourists',
        director: 'El Kheyer Zidani',
        producer:'El Kheyer Zidani',
        type: 'Documentary',
        year: '2018',
        duration:'16',
    },
    {
        id: 2,
        img: '/img/films/the last station.jpg',
        title: 'The last station',
        synopcis: 'Forward a man but through his job: Mohamed Tahar Ben Nada. A very special profession is the one who takes care of the funeral home, which can surprise or even confuse. Yet he is not the only one to practice it. Through this man, Khaled Khemis does not want to trivialize this profession, but rather to "humanize" it, on the one hand. On the other hand, to remember when in life we can run in all directions, in the end, we end up in the hands of a "washer". It is the last station before the big departure',
        director: 'Khemis Khaled',
        producer:'El Kheyer Zidani',
        type: 'Documentary',
        year: '2018',
        duration:'10',
    },
    {
        id: 3,
        img: '/img/films/effet.jpg',
        title: 'Effect',
        synopcis: 'Effect is a short feature film that tells the true story of a separated family. Nada is a victim of floral skin vine, Her mother abandons them and leaves them. Then the father takes care of her and bears all the  burdens',
        director: 'Ferhat Okba',
        producer:'El Kheyer Zidani',
        type: 'Fiction',
        year: '2019',
        duration:'11',
    },
    {
        id: 4,
        img: '/img/films/guem sur le vif.jpg',
        title: 'Guem sur le vif',
        synopcis: 'An elderly photographer from the classical school represents the category of wrestling with the reality of retirement and the consequent health and psychological problems imposed by a society in the shadow of the dominance of digitization on the field of his profession, though, at a very advanced age seeking to keep up with digitalization of the image with an old reference',
        director: 'Khemis Khaled',
        producer:'El Kheyer Zidani',
        type: 'Documentary',
        year: '2019',
        duration:'20',
    },
    {
        id: 5,
        img: '/img/films/protest camp.jpg',
        title: 'Protest Camp',
        synopcis: 'The film it is about Ali, Idis and Prissilla they live in protest camp in Berlin   and they continue their fight for recognition as political refugees and tale it the next level',
        director: 'El Kheyer Zidani',
        producer:'El Kheyer Zidani',
        type: 'Documentary',
        year: '2014',
        link: 'https://vimeo.com/98148607',
        duration:'26',
    },
    {
        id: 6,
        img: '/img/films/my dads a farmer.jpg',
        title: "My dad's a farmer",
        synopcis: 'The film tells a personal story with my parents between the past and the present. My childhood was mixed with fear and psychological distress, which happened with terrorism and beheading everywhere during the 90s years that would have been created in a broken human being, were religious extremist. My mother works as a dressmaker in the family home while my father works in agriculture in another city far from the family for this reason my parents became a separate couple and they meet one’s every month despite more than 36 years of love marriage',
        director: 'El Kheyer Zidani',
        producer:'El Kheyer Zidani',
        type: 'Documentary',
        duration:'90',
        status:'In developpement',
    },
    {
        id: 7,
        img: '/img/films/prova.jpg',
        title: "PROVA",
        synopcis: 'Story of an artist who performs a parody of a play in a virtual format to participate in a national competition. In front of a personal and artistic challenge, which puts us in front of a reality hypothesis to understand the life of the actor who wants to prove his worth not only by performing his theatrical character, but also the film puts him before the challenge of proving himself between the artistic and family circles through the scenes in the garage in which he prepares himself and prepares to face the audience who awaits his viewers in life And in art. It enables him to say what he cannot say in reality, looking for audience interaction, because theater is a living, interactive, and aesthetic art that is transmitted to the audience directly, and this is not available in the virtual theater.',
        director: 'Khaled Khemis',
        producer:'El Kheyer Zidani',
        type: 'Documentary',
        link: 'https://www.youtube.com/watch?v=Y_dfF05gc9Y',
        duration:'26',
        status:'Post Production',
    },
    {
        id: 8,
        img: '/img/films/Deadly Business Poster.jpg',
        title: "Khedmet l'mouth",
        synopcis: 'This documentary reveals the hardships faced by young Berber villagers whose only form of livelihood is stone-cutting in the quarry, located in the heart of the Algerian Desert. As a result of their work, many of them suffer from lung disease. The film will follow a dying young man, his still healthy workmate who refuses to quit his job, and the driver who voluntarily supplies the masks for the workers',
        director: 'El Kheyer Zidani',
        producer:'El Kheyer Zidani & Julia Peters',
        type: 'Documentary',
        year: '',
        link:'https://vimeo.com/507172155',
        duration:'85',
        status:'Production',
    },
    {
        id: 9,
        img: '/img/films/The golden throat, Aissa El DJermouni.jpg',
        title: "The golden throat, Aissa El DJermouni",
        synopcis: 'It is the biographical story of the legend, the symbol of Chaoui authenticity, and the owner of the golden throat, Aissa El DJermouni, His songs revolved around singing about the Chaoui man, as well as colonialism, in addition to singing about women. He is also the first African and Arab who sang at the Olympia Theater during 1937.',
        director: 'El Kheyer Zidani',
        producer:'Z&K PRODUCTION/ Aljazeera Documentary',
        type: 'Documentary',
        year: '2022',
        duration:'26',
    },

];

export const menuItems = [

    {
        id: 1,
        name: 'ABOUT US',
        link: '/about',
    },
    {
        id: 2,
        name: 'Catalogue',
        link: '/catalogue',
    },
    {
        id: 3,
        name: 'CONTACT',
        link: '/contact',
    },
];

export const email = 'zkproduction05@gmail.com';