import React from 'react'
import { menuItems } from '../../data'
import { Link } from 'react-router-dom';

const Menu = () => {

    return (
        <div className='menu'>
            {menuItems.map(item => <div className={`menuItem ${window.location.pathname === item.link && 'active'}`} ><Link to={item.link} >{item.name}</Link></div>)}
        </div>
    )
}

export default Menu