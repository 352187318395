import React from 'react'
import Menu from './Menu'
import './Navbar.css'
import { Link } from 'react-router-dom';


const Navbar = () => {
  return (
    <div className='containerNavbar'>
      <Link to='/' style={{ textDecoration: 'none' }}>
        <img src='/img/Z&K Production.png' className='logo' alt='' />
      </Link>
      <Menu />
    </div>
  )
}

export default Navbar
