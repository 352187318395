import './BodyCatalogue.css'
import { ListFilms } from '../../data'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Catalogue = () => {

  const [isLoading,  setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(false);
  },[]);

  // function shuffle(arra1) {
  //   var ctr = arra1.length,
  //     temp,
  //     index;
  //   while (ctr > 0) {
  //     index = Math.floor(Math.random() * ctr);
  //     ctr--;
  //     temp = arra1[ctr];
  //     arra1[ctr] = arra1[index];
  //     arra1[index] = temp;
  //   }
  //   return arra1;
  // }

  // const ListFilmsRandom = shuffle(ListFilms);

  return (

    <div className='catalogueContainer'>
      <div className='catalogueWrapper'>

        <h2> Feature films </h2>
        {/* <h1 className='bfTitle'> Feature films <span class="arrow"></span> </h1> */}

        <div className='filmCards'>

        {isLoading ? 
        <div><img src='/img/loading.gif' alt=''  /></div> :


        ListFilms.map(film => film.duration > 52 && <Link to={'/film/' + film.id} style={{ textDecoration: 'none' }}>
        <div className='filmCard' key={film.id}>
          <img src={film.img} alt='img' className='imgFilm' />
          <div className="filmInfo">
            <span className='filmTitle'>{film.title}</span>
            <span className='filmDirector'>By {film.director}</span>
            {film.year && <span className='filmYear'>Annee: {film.year}</span>}
          </div>
        </div>
      </Link>
          
        )


        }
          
        </div>


        {/* <h1 className='bfTitle'> Short films <span class="arrow"></span> </h1> */}
        <h2> Short films </h2>

        <div className='filmCards'>

        {isLoading ? 
        <div><img src='/img/loading.gif' alt=''  /></div> :


        ListFilms.map(film => film.duration <= 52 && <Link to={'/film/' + film.id} style={{ textDecoration: 'none' }}>
        <div className='filmCard' key={film.id}>
          <img src={film.img} alt='img' className='imgFilm' />
          <div className="filmInfo">
            <span className='filmTitle'>{film.title}</span>
            <span className='filmDirector'>By {film.director}</span>
            {film.year && <span className='filmYear'>Year: {film.year}</span>}
          </div>
        </div>
      </Link>
          
        )


        }
          
        </div>

      </div>
    </div>
  )
}

export default Catalogue