import React from 'react'
import Menu from '../Navbar/Menu'
import './Footer.css'
import SocialMedia from './SocialMedia'
import { email } from '../../data'

const Footer = () => {
    return (
        <div className='footerContainer'>
            <div className="footerWraper">
                <div className="leftFooter"><Menu /> </div>
                <div className="rightFooter">
                    <SocialMedia />
                    <span>  +213 555 30 88 91 </span>
                    <span>{email}</span>
                </div>
            </div>
            <div className="copyright">Copyright 2022 - All Rights Reserved - site by Younes Bouali</div>
        </div>
    )
}

export default Footer