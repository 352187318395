import './BodyFilm.css'
import { ListFilms } from '../../data'
import { useEffect } from 'react';
import { useLocation } from "react-router-dom"
import { PlayCircleFilled, PlayCircleFilledOutlined, PlayCircleOutline } from '@material-ui/icons';

const BodyFilm = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();
    const id = location.pathname.split('/')[2];

    const film = ListFilms.find(obj => {
        return obj.id === parseInt(id, 10);
    });

    return (
        <div className='filmContainer'>
            {film ? <div className='filmWrapper'>
                <div className="top">
                    <img src={film.img} alt='img' className="filmImage" />
                    <div className="filmInfo">
                        <span className="title">{film.title}</span>
                        <div className='infoItem'><span className="info1"><b>Genre: </b></span><span className="info2">{film.type}</span></div>
                        {film.year && <div className='infoItem'><span className="info1"><b>Year: </b></span><span className="info2">{film.year}</span></div>}
                        <div className='infoItem'><span className="info1"><b>Director: </b></span><span className="info2">{film.director}</span></div>
                        <div className='infoItem'><span className="info1"><b>Producer: </b></span><span className="info2">{film.producer}</span></div>
                        {film.status && <div className='infoItem'><span className="info1"><b>Stage: </b></span><span className="info2">{film.status}</span></div>}
                        {film.duration && <div className='infoItem'><span className="info1"><b>Duration: </b></span><span className="info2">{film.duration} minutes</span></div>}
                        <br />
                        <span className="synopcisTitle"><b>Synopsis:</b></span>
                        <span className="synopcis">{film.synopcis}</span>
                    </div>
                </div>
                {film.link && <div className="bottom">
                    <a href={film.link} target="_blank">
                        <div className='videoLink'>
                            <div className="backgroundVideo"></div>
                            <img src={film.img} alt='img' className="videoImg" />
                            <PlayCircleFilled style={{height:'70px', width:'70px'}} className='playIcon'/>
                        </div>
                    </a>

                    {/* <iframe width="853" height="480" src={'https://www.youtube.com/embed/' + film.link} title="Teaser PROVA Documentary Film" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                </div>}

            </div> : <span>non one</span>}

        </div>
    )
}

export default BodyFilm