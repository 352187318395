import './BodyContact.css'
import { HomeRounded, Email, Phone, SendRounded, Facebook } from '@material-ui/icons'
import { useState } from 'react';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { email as emailBoite } from '../../data';

const BodyContact = () => {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [pending, setPending] = useState(null);

    const sendingGif = '/img/loading.gif';

    const form = useRef();

    const sendEmail = async (e) => {

        e.preventDefault();

        setPending(true);
        setError(null);
        setSuccess(null);

        emailjs.sendForm('service_3johow6', 'template_hoetcel', form.current, 'bCrx-8waBPOBpUE-y')
            .then((result) => {
                console.log(result.text);

                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 3000);
                
                setPending(false);
                setName('');
                setEmail('');
                setMessage('');
            }, (error) => {
                console.log(error.text);
                setPending(false);
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });
    };

    return (
        <div className='contactContainer' id='contact'>

            {/* <div className="loading">
                <img src={sendingGif} alt='' />
            </div> */}


            <div className="wrapper">
                <div className="cordonner">
                    <ul className="listCordonner">
                        <li className="listItem">
                            <HomeRounded className='icon' />
                            <div className="contentList">
                                <h3 className="cordonnerTitle">Our Office adress</h3>
                                <span className="cordonnnerDesc">30 Tamechit Str batna - Algeria</span>
                            </div>
                        </li>
                        <li className="listItem">
                            <Email className='icon' />
                            <div className="contentList">
                                <h3 className="cordonnerTitle">Email</h3>
                                <span className="cordonnnerDesc">{emailBoite}</span>
                            </div>
                        </li>
                        <li className="listItem">
                            <Phone className='icon' />
                            <div className="contentList">
                                <h3 className="cordonnerTitle">Phone</h3>
                                <span className="cordonnnerDesc">+213 555 30 88 91</span>
                            </div>
                        </li>
                    </ul>
                    {/* <SocialMedia className='socialMediaContact'/> */}
                </div>
                <div className="right">
                    <form ref={form} className='form' onSubmit={sendEmail}>
                        <label><b>Name *</b></label><input type='text' className='contactInput' placeholder='Your Name' name='user_name' value={name} onChange={e => setName(e.target.value)} required />
                        <label><b>Email *</b></label><input type='text' className='contactInput' placeholder='Your Email' name='user_email' value={email} onChange={e => setEmail(e.target.value)} required />
                        <label><b>Message *</b></label><textarea className='contactMessgae' placeholder='Your Message' name='message' value={message} onChange={e => setMessage(e.target.value)} required />
                        <button className='contactButton' disabled={pending}>{pending ? <>SENDING <img src={sendingGif} alt='' className='loadingImg' /></> : <>SEND <SendRounded /></>}</button>
                        {error && <span className='error'>Something wrong !</span>}
                        {success && <span className='success'>Thanks I will reply</span>}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BodyContact