import './BodyAbout.css'

const BodyAbout = () => {
    return (
        <div className='aboutContainer'>
            <div className='aboutWrapper'>
                <div className="aboutContent">
                    <img src='/img/Z&K Production.png' alt='' className='logoAbout' />
                    <div className="aboutInfo">
                        <p>Z&K PRODUCTION is an Algerian  film production company founded in 2010 by El kheyer Zidani
                            Since its creation, Z&K PRODUCTION has set itself the following main objectives:
                        </p>
                        <br/>
                        <ul>
                            <li>To offer its customers the best services on the market at the most studied and competitive costs</li>
                            <li>Call on the best national and foreign skills in the fields of audiovisual and cinematographic production</li>
                            <li>The collaboration with these skills is done either with permanent contracts or through fixed-term partnership agreements on specific projects</li>
                            <li>Look for young talents directors and offer them their first experience. This approach aims to help these new talents to emerge, with the desire to make them future employees of the company</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BodyAbout