import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import BodyCatalogue from '../components/BodyCatalogue/BodyCatalogue'

const Catalogue = () => {
    return (
        <div>
            <Navbar />
            <BodyCatalogue />
            <Footer />
        </div>
    )
}

export default Catalogue