import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import BodyAbout from '../components/BodyAbout/BodyAbout'

const About = () => {
  return (
    <div>
      <Navbar />
      <BodyAbout />
      <Footer />
    </div>
  )
}

export default About