import Home from './pages/Home'
import About from './pages/About'
import Catalogue from './pages/Catalogue'
import Film from './pages/Film'
import Contact from './pages/Contact'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/catalogue' element={<Catalogue />} />
        <Route path='/film/:id' element={<Film />}/>
        <Route path='/contact' element={<Contact />}/>
      </Routes>
    </Router>

  )
}

export default App
